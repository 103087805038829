import { makeStyles } from '@mui/styles';

export const style = makeStyles((theme) => ({
  avatarStyle: {
    '&.MuiAvatarGroup-avatar': {
      height: '20px',
      width: '20px',
    },
  },
}));

export const style1 = {
  py: '30px',
  position: 'relative',
  mb: '12px',
};
export const textBox = {
  textAlign: { xs: 'center', md: 'left' },
  ml: { md: 3 },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: { xs: 'center', md: 'flex-start' },
};
export const style2 = {
  display: { md: 'flex' },
  justifyContent: 'flex-start',
};

export const style4 = {
  height: { xs: '64px', md: '133px' },
  minWidth: { xs: '64px', md: '143px' },
  width: { xs: '64px', md: '133px' },
  borderRadius: '50%',
};
export const style9 = { backgroundColor: '#faeb50', mt: '4px' };

export const dFlex = {
  display: 'flex',
  mb: '4px',
};
export const shareContainer = {
  mb: 2,
  alignSelf: 'flex-end',
  display: 'flex',
  alignItems: 'center',
  position: { xs: 'absolute', md: 'unset' },
  top: 35,
};

export const style11 = { mr: 3, color: 'rgba(0, 0, 0, 0.54)' };

export const rightContainer = {
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: { xs: 'center', md: 'flex-start' },
};

export const gridStyle1 = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};
