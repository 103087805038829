import React from 'react';
import { Box, Typography } from '@mui/material';
import { Rating } from '@mui/material';

import LazyImage from '../LazyImage';
import KeenSlider from '../KeenSlider';
import { dFlex } from '../../LearnerProfile/UserDetails/style';

interface TextTestimonialInterface {
  data?: any;
}

const TextTestimonial = ({ data }: TextTestimonialInterface) => {
  const settings = {
    slides: {
      spacing: 15,
      perView: () => {
        return window.innerWidth / 300;
      },
    },
    breakpoints: {
      '(min-width: 900px)': {
        slides: {
          spacing: 15,
          perView: () => {
            return window.innerWidth / 525;
          },
        },
      },
    },
    snap: 'free',
  };

  return (
    <KeenSlider settings={settings} arrows>
      {data?.reviews?.map((review, key) => (
        <div
          key={key}
          className="keen-slider__slide"
          style={{ marginBottom: 10 }}
        >
          <Box
            padding={2}
            borderRadius={2}
            mb={2}
            sx={{
              ...dFlex,
              flexWrap: 'wrap',
              height: '99%',
            }}
            mx={'1px'}
            minHeight={248}
            boxShadow={` 0 2px 3px 0 rgba(0, 0, 0, 0.16)`}
          >
            <User data={review} />
            {review.rating && (
              <Rating
                sx={{ width: '100%' }}
                name="rating"
                value={review.rating}
              />
            )}
            <Typography variant="h6">{data?.name}</Typography>
            <Box
              component={Typography}
              minHeight={144}
              variant="body1"
              paragraph
            >
              {review?.review}
            </Box>
            {review?.reviewOf && (
              <Typography color="textSecondary" variant="body2">
                {review.reviewOf}
              </Typography>
            )}
          </Box>
        </div>
      ))}
    </KeenSlider>
  );
};

export default TextTestimonial;

export const User = ({ data }) => {
  const sizes = {
    xxl: [64, 64, false],
  };

  return (
    <Box
      ml={1}
      mb={3}
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridGap: '9px',
        alignItems: 'center',
      }}
      minHeight="92px"
    >
      <LazyImage
        radius={'50%'}
        sizes={sizes}
        style={{ width: 61, height: 61 }}
        src={data?.img}
      />

      <div>
        <Typography variant="h6">{data?.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {data?.userId}
        </Typography>
      </div>
    </Box>
  );
};
