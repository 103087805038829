import React, { memo, useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import TextTestimonial from '../../Layouts/TextTestimonial';

import style from './style';
import KeenSlider from '../../Layouts/KeenSlider';

interface VideoTestimonialInterface {
  data: any;
  reviewData?: any;
}

const VideoTestimonial = ({ data, reviewData }: VideoTestimonialInterface) => {
  const classes = style();

  const matches = useMediaQuery('(min-width:1200px)');

  const settings = {
    slides: {
      spacing: 10,
      perView: () => {
        return window.innerWidth / 275;
      },
    },
    loop: true,
  };

  const videoElement = useRef([]);
  const videoContainer = useRef<null | HTMLDivElement>(null);

  const handlePlay = (e) => {
    for (let i = 0; i < videoElement.current.length; i++) {
      if (videoElement.current[i] != e.target) {
        videoElement.current[i].pause();
      }
    }
  };

  const fullScreenExitHandler = () => {
    if (typeof window !== 'undefined' && !videoContainer.current) {
      videoContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  useEffect(() => {
    [
      'fullscreenchange',
      'mozfullscreenchange',
      'webkitfullscreenchange',
      'MSFullscreenChange',
    ].forEach((eventType) => {
      document.addEventListener(eventType, fullScreenExitHandler, false);
    });

    return () => {
      [
        'fullscreenchange',
        'mozfullscreenchange',
        'webkitfullscreenchange',
        'MSFullscreenChange',
      ].forEach((eventType) => {
        document.removeEventListener(eventType, fullScreenExitHandler, false);
      });
    };
  }, []);

  return (
    <Container component={Box} my={{ xs: 7, md: 15 }}>
      <Grid container justifyContent="center" ref={videoContainer}>
        <Grid item md={7} component={Box} textAlign="center">
          <Typography variant="h2" gutterBottom>
            {data?.title}
          </Typography>
          <Typography variant="body1" paragraph>
            {data?.sub}
          </Typography>
        </Grid>
      </Grid>

      <Box mt={5} />

      {!matches ? (
        <KeenSlider settings={settings}>
          {data.videoReview.map((review, key) => (
            <Grid key={key} className="keen-slider__slide">
              <Box mb={2} position="relative" sx={{ minHeight: 400 }}>
                <video
                  onPlay={handlePlay}
                  controls
                  ref={(el) => (videoElement.current[key] = el)}
                >
                  <source
                    src={`${process.env.NEXT_PUBLIC_ASSETS}${review.video}`}
                  />
                </video>
              </Box>
              <Typography variant="h5" gutterBottom>
                {review?.name}
              </Typography>
              <Typography variant="body1">{review?.profile}</Typography>
            </Grid>
          ))}
        </KeenSlider>
      ) : (
        <Box className={classes.testimonialContainer}>
          {data.videoReview.map((review, key) => (
            <Grid item xs={12} key={key}>
              <Box mb={2} position="relative">
                <video
                  controls
                  onPlay={handlePlay}
                  ref={(el) => (videoElement.current[key] = el)}
                  className={classes.videoBox}
                >
                  <source
                    src={`${process.env.NEXT_PUBLIC_ASSETS}${review.video}`}
                  />
                </video>
              </Box>
              <Typography variant="h5" gutterBottom>
                {review?.name}
              </Typography>
              <Typography variant="body1">{review?.profile}</Typography>
            </Grid>
          ))}
        </Box>
      )}

      <Box>
        <TextTestimonial data={reviewData || data} />
      </Box>
    </Container>
  );
};

export default memo(VideoTestimonial);
