import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  playButtonBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.6,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  testimonialContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    justifyContent: 'center',
    margin: '25px auto',
    gridGap: theme.spacing(2),
  },

  videoBox: { borderRadius: 8 },

  testimonialImg: {
    height: '400px',
    '& > img': {
      objectFit: 'cover',
    },
  },
}));
export default style;
